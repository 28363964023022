const subscriptionList = [
  {
    id: '1',
    name: 'sales',
    description: '/crm/sales',
    status: 'In Trial',
    plan: 'Enterprise Plan',
    daysRemaining: '10',
    trialEnds: '28 Jul 2022',
  },
  {
    id: '2',
    name: 'sales',
    description: '/crm/sales',
    status: 'In Trial',
    plan: 'Enterprise Plan',
    daysRemaining: '25',
    trialEnds: '08 Aug 2023',
  },
  {
    id: '3',
    name: 'sales',
    description: '/crm/sales',
    status: 'In Trial',
    plan: 'Enterprise Plan',
    daysRemaining: '13',
    trialEnds: '10 May 2022',
  },
  {
    id: '4',
    name: 'sales',
    description: '/crm/sales',
    status: 'In Trial',
    plan: 'Enterprise Plan',
    daysRemaining: '13',
    trialEnds: '10 May 2022',
  },
  {
    id: '5',
    name: 'sales',
    description: '/crm/sales',
    status: 'In Trial',
    plan: 'Enterprise Plan',
    daysRemaining: '13',
    trialEnds: '10 May 2022',
  },
  {
    id: '6',
    name: 'sales',
    description: '/crm/sales',
    status: 'In Trial',
    plan: 'Enterprise Plan',
    daysRemaining: '13',
    trialEnds: '10 May 2022',
  },
  {
    id: '7',
    name: 'sales',
    description: '/crm/sales',
    status: 'In Trial',
    plan: 'Enterprise Plan',
    daysRemaining: '13',
    trialEnds: '10 May 2022',
  },
];

export default subscriptionList;
